import React, { useContext, useMemo } from "react";
import "./index.css";
import { PacoteConsumoContext } from "../../../context/PacoteConsumoContext";
import { TabelaConsumoMeio } from "./TabelaConsumoMeio";

export function ConsumoConectaTabelas(){
  const {valoresPacotesConsumo, canalAtivo} = useContext(PacoteConsumoContext);

  const tabelas = useMemo(()=>{
    if(Object.keys(valoresPacotesConsumo).length){ 
      if(canalAtivo){
        let canal = ['whatsapp-conversas', 'whatsapp-templates'].includes(canalAtivo) ? 'whatsapp' : canalAtivo;
        return [
          <TabelaConsumoMeio slug={canal}/>
        ]
      }else{
        return Object.keys(valoresPacotesConsumo).map((key, index)=>{
          return(
            <TabelaConsumoMeio key={index} slug={valoresPacotesConsumo[key]?.meio_comunicacao ?? key}/>
          )
        })
      }
    }
    return [];
  },[valoresPacotesConsumo, canalAtivo])

  return (
    <div className="consumo-tabelas-section">
      {tabelas}
    </div>
  )
}
