import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import DommusToggle from "../../../../components/DommusToggle";
import DommusTabelaPaginadaBack from "../../../../components/DommusTabelaPaginadaBack"
import { errorToast } from '../../../../components/DommusToast';
import PacoteConsumoService from "../../../../services/PacoteConsumoService";
import { PacoteConsumoContext } from "../../../../context/PacoteConsumoContext";
import { DommusAlerta} from "../../../../components/DommusAlerta";
import formatDommus from "../../../../helpers/format";
import { Button, Spinner, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import fileDownload from "js-file-download";

export function TabelaConsumoMeio({slug}){
  const {mesReferencia} = useContext(PacoteConsumoContext);
  const [isToggle, setIsToggle] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingExportacao, setLoadingExportacao] = useState(false);
  const [dados, setDados] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(8);
  const [total, setTotal] = useState(0);
    

  const buscarDadosLista = () => {
    setLoading(true);
    const filtros = {
      page: page,
      limit: limit,
      mes_referencia: mesReferencia,
      buscar_total: !total
    }
    PacoteConsumoService.buscarDadosTabela(6, slug, filtros).then((response)=>{
      setDados(response?.data?.dados);
      if(response?.data?.total){
        setTotal(response?.data?.total)
      }
      setTimeout(()=>{
        setLoading(false);
      },200)
    }).catch((error)=>{
      setLoading(false)
    })
  }

  const title = useMemo(()=>{
    let title = "";
    switch(slug){
      case 'email':
        title = "E-mail";
        break;
      case 'sms':
        title = "SMS";
        break;
      case 'whatsapp':
        title = "Whatsapp";
        break;
      case 'voip':
        title = "Voip";
        break;
      default:
        break;
    }

    return (
      <div className="toggle-title-section">
        <span className="title-text">{title}</span>
        {isToggle ?
          <Button 
            variant="success" className="button-exportar" 
            onClick={()=>{exportarDados()}}
            disabled={loadingExportacao}
          >{
            loadingExportacao ?
                <span className="span-section">
                  <Spinner size="sm"  animation="border" /> Carregando
                </span>
            :
                <span className="span-section">
                  <FontAwesomeIcon icon={faFileExcel}/> Exportar
                </span>
            }
          </Button>
        : <></>}
      </div>
    )
  },[slug, isToggle, loadingExportacao])

  const exportarDados = ()=>{
    setLoadingExportacao(true);
    const filtros = {
      page: page,
      limit: limit,
      mes_referencia: mesReferencia,
      buscar_total: !total
    }

    PacoteConsumoService.exportarDadosTabela(6, slug, filtros).then((response)=>{
      setLoadingExportacao(false);
      fileDownload(
        response.data,
        "relatorio-" + new Date().toISOString() + ".csv"
      );
    }).catch((error)=>{
        errorToast.fire({ text: 'Houve um erro ao tentar exportar os dados da tabela.' })
      setLoadingExportacao(false);
    })
  }

  const theader = useMemo(()=>{
    return (
      <tr className="text-center">
        <th className="text-center" style={{width: '10%'}}>Data</th>
        <th className="text-left" style={{width: slug === 'email' ? '18%' : '12%'}}>Contato Origem</th>
        <th className="text-left" style={{width: slug === 'email' ? '18%' : '12%'}}>Contato Destino</th>
        {slug === 'voip' ? 
          <th className="text-left">Duração</th>
        :
          <th className="text-left " style={{width: '40%'}}>Mensagem</th>
        }
        <th className="text-left" style={{width: "12%"}}>Direção Atendimento</th>
      </tr>
    )
  },[])
  
  useEffect(()=>{
    if(isToggle && !dados.length){
      buscarDadosLista();
    }
  },[isToggle])

  useEffect(() => {
    if(isToggle){
      buscarDadosLista();
    }
  },[page, limit])
  
  const trows = useMemo(()=>{
    return dados?.map((item, index)=>{
      return (
        <tr key={index}>
                <td className="text-center">{item.data_hora}</td>
                <td>{slug === 'email' ? item.contato_origem : formatDommus.telefoneMask(item.contato_origem)}</td>
                <td>{slug === 'email' ? item.contato_destino: formatDommus.telefoneMask(item.contato_destino)}</td>
                {slug === 'voip' ?
                  <td className="mensagem-row">{item.conteudo}</td>
                  :
                  <td className="mensagem-row">{item.conteudo}</td>
                } 
                <td className="text-center">{item.direcao}</td>
        </tr>
      )
    })
  },[dados])

  return (
    <div className="table-consumo">
      <DommusToggle title={title} toggle={isToggle} callback={setIsToggle}>
        {loading ? 
          <div className="skeleton-table">
              <div className="skeleton-header skeleton-animation"/>
              <div className="skeleton-rows">
                  <div className="skeleton-row skeleton-animation"></div> 
                  <div className="skeleton-row skeleton-animation"></div> 
                  <div className="skeleton-row skeleton-animation"></div> 
                  <div className="skeleton-row skeleton-animation"></div> 
              </div>
          </div>
        :
          trows ? 
            <DommusTabelaPaginadaBack
                data={null}
                setLimit={setLimit} 
                limit={limit}
                setPage={setPage}
                page={page} 
                linhasCabecalho={theader}
                linhasCorpo={trows}
                total={total}
            />
          :
            <DommusAlerta mensagem="Nenhum dado foi encontrado para o período selecionado." classe="text-center"/>
        }
      </DommusToggle>
    </div>
  )
}
