import api from "./api";

const PacoteConsumoService = {
    buscarPorModulo: (idModulo, mesReferencia=null) => {
        return api.get(`pacote-consumo/modulo/${idModulo}`, {params: {mes_referencia: mesReferencia}});
    },
    buscarConsumoPorModulo: (idModulo) => {
        return api.get(`pacote-consumo/consumo/modulo/${idModulo}`);
    },
    buscarConsumoPorModuloPorMeio: (idModulo, meio, filtros=null) => {
        return api.get(`pacote-consumo/consumo/modulo/${idModulo}/meio/${meio}`, {params:{filtros}});
    },
    buscarDadosTabela: (idModulo, meio, filtros) =>{
      return api.get(`pacote-consumo/consumo/modulo/${idModulo}/meio/${meio}/tabela`, {params:{filtros}});
    },
    exportarDadosTabela: (idModulo, meio, filtros) =>{
      return api.get(`pacote-consumo/consumo/modulo/${idModulo}/meio/${meio}/tabela/exportar`, {params:{filtros}});
    }
};

export default PacoteConsumoService;
