const formatDommus = {
    formatDate: (date) => {
        var date = new Date(date)
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        if (interval > 1) {
            return Math.floor(interval) + " anos atrás";
        }
        interval = seconds / 2592000;
        if (interval > 1) {
            return Math.floor(interval) + " mes(es) atrás";
        }
        interval = seconds / 86400;
        if (interval > 1) {
            return Math.floor(interval) + " dia(s) atrás";
        }
        interval = seconds / 3600;
        if (interval > 1) {
            return Math.floor(interval) + " hora(s) atrás";
        }
        interval = seconds / 60;
        if (interval > 1) {
            return Math.floor(interval) + " minuto(s) atrás";
        }
        return Math.floor(seconds) + " segundo(s) atrás";
    },

    contarAtraso: (date) => {
        var date = new Date(date)
        var dias = 0;
        var seconds = Math.floor((new Date() - date) / 1000);
        var interval = seconds / 31536000;
        interval = seconds / 86400;
        if (interval > 1) {
            if (Math.floor(interval) > 3){
                return Math.floor(interval) - 3;
            }
        }
    },

    zeroPad: (num, places) => {
        return String(num).padStart(places, '0')
    },

    formataMysql: (data) => {
        if (data != undefined && data != '') {

            var dia = data.split("/")[0];
            var mes = data.split("/")[1];
            var ano = data.split("/")[2];

            return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
        }else{
            return null;
        }

    },

    formatarReal: (valor) => {
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },

    resolveNomeMesPorNumero: (valor) => {
      switch(Number(valor)){
        case 1:
          return "Janeiro";
        case 2:
          return "Fevereiro";
        case 3:
          return "Março";
        case 4:
          return "Abril";
        case 5:
          return "Maio";
        case 6:
          return "Junho";
        case 7:
          return "Julho";
        case 8:
          return "Agosto";
        case 9:
          return "Setembro";
        case 10:
          return "Outubro";
        case 11:
          return "Novembro";
        case 12:
          return "Dezembro";
        default:
          return "";
      }
    },

    verificarDDIBrasileiro: (celular) => {
      let telefone = String(celular)

      return (telefone.substring(0, 3) === '+55') || (telefone.substring(0, 2) === '55')
    },

    telefoneMask: (value) => {	
      if ((value || false) && value.substr(0,1) !== '+') {
          const onlyDigits = value.replace(/[^0-9]/g, '');
          let searchPrimeiroSegmento = /(\d{4})(\d)/;
          if (onlyDigits.length > 10) {
              searchPrimeiroSegmento = /(\d{5})(\d)/;
          }
          if ( onlyDigits.length === 12 ) {
              let regexNumeroBrasilCom55 = /(\d{2})(\d{2})(\d{4})(\d{4})/;
              let resultado = regexNumeroBrasilCom55.test(onlyDigits);
              if ( resultado ) {
                  return onlyDigits.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, '+$1 ($2) $3-$4');
              }
          }

          if ( onlyDigits.length === 13 ) {
              let regexNumeroBrasilCom55 = /(\d[55])(\d{2})(\d{5})(\d{4})/;
              let resultado = regexNumeroBrasilCom55.test(onlyDigits);
              if ( resultado ) {
                  return onlyDigits.replace(/\D/g, '').replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
              } else {
                  return `+${onlyDigits}`
              }
          }
          return onlyDigits
              .replace(/\D/g, '')
              .replace(/(\d{2})(\d)/, '($1) $2')
              .replace(searchPrimeiroSegmento, '$1-$2')
              .replace(/(-\d{4})\d+?$/, '$1')
      } else if((value || false) && value.substr(0,1) === '+'){
          const onlyDigits = value.replace(/[^0-9]/g, '');
          if(!formatDommus.verificarDDIBrasileiro(onlyDigits)) {
              return `+${onlyDigits}`
          }
          let searchSegundoSegmento = /(\d{4})(\d)/;
          if (onlyDigits.length > 10) {
              searchSegundoSegmento = /(\d{5})(\d)/;
          }
          return onlyDigits
              .replace(/\D/g, '')
              .replace(/(\d{2})(\d{2})(\d)/, '($2) $3')
              .replace(searchSegundoSegmento, '$1-$2')
              .replace(/(-\d{4})\d+?$/, '$1')
      }

      return null;
  },
  

}
export default formatDommus;
