import moment from "moment";
import React, { createContext, useState, useEffect } from "react";

export const PacoteConsumoContext = createContext({});

export function PacoteConsumoProvider({ children }) {
    const [pacotesConsumo, setPacotesConsumo] = useState([]);
    const [valoresPacotesConsumo, setValoresPacotesConsumo] = useState([]);
    const [meiosComunicacaoPacotes, setMeioComunicacaoPacotes] = useState([]);
    const [canalAtivo, setCanalAtivo] = useState(null);
    const [mesReferencia, setMesReferencia] = useState(moment().format('Y-MM'));

    useEffect(()=>{
        if(pacotesConsumo.length){
            setMeioComunicacaoPacotes([...new Set(pacotesConsumo.map((item) => item?.meio_comunicacao))]);
        }
    },[pacotesConsumo])

    const checarMesReferenciaIsAtual = () => {
      let dataMesReferencia = moment(`${mesReferencia}-01`);
      let now = moment();
      return dataMesReferencia.month === now.month && dataMesReferencia.year === now.year;
    }

    const dataFinalPeriodo = () => {
      if(checarMesReferenciaIsAtual){
        return moment().format('YYYY-MM-DD');    
      }else{
        return moment().endOf('month').format('YYYY-MM-DD');
      }
    }
    
    return (
        <PacoteConsumoContext.Provider
            value={{
                pacotesConsumo,
                setPacotesConsumo,
                meiosComunicacaoPacotes,
                canalAtivo,
                setCanalAtivo,
                valoresPacotesConsumo,
                setValoresPacotesConsumo,
                mesReferencia,
                setMesReferencia,
                checarMesReferenciaIsAtual,
                dataFinalPeriodo,
            }}
        >
            {children}
        </PacoteConsumoContext.Provider>
    );
}
