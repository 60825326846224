import './index.css';
import React, { useState, useEffect, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import DommusLoadingArea from "../../components/DommusLoading/DommusLoadingArea";
import { Alert, Card, Col, Row } from 'react-bootstrap'
import DommusToggle from "../../components/DommusToggle";
import { GraficoService } from '../../services/GraficoService';
import DommusLoading from '../DommusLoading/DommusLoading';
import moment from 'moment';
import { DommusAlerta } from '../DommusAlerta';
import { errorToast } from '../DommusToast';
import DommusTabelaPaginada from '../DommusTabelaPaginada';

function coresHighchart(){
	let cores = [
		'#09bfdf', '#6b71bb', '#81adfa', '#92ca10', '#429b21', 
		'#52534b', '#ea9e72', '#e21715', '#becae1', '#1289d9', 
		'#3ae63e', '#8c5cf4', '#2860be', '#6b14cf', '#169963', 
		'#11eb8a', '#ac6686', '#5e9822', '#c8ff55', '#4ec48f', 
		'#944bc7', '#a16752', '#8ed9c9', '#de5f65', '#960d63', 
		'#bfa056', '#3d4520', '#0e04c7', '#b7fe6e', '#71c2ba', 
		'#73f7b2', '#83fdf8', '#a39bc2', '#b8ff22', '#d9f2c6', 
		'#56f112', '#8f0455', '#308163', '#579030', '#5d40ba', 
		'#e224f7', '#8186e6', '#98e10b', '#e59a8d', '#6f399a', 
		'#41bcf6', '#51c074', '#2c657e', '#0e84d9', '#128b4b'
	];

	for (let i = cores.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [cores[i], cores[j]] = [cores[j], cores[i]];
    }

	return cores;
}

const DommusCharts = {
	PieChart: ({ titulo, chave, filtrosGlobais, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState([]);
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')

		let opcoesGrafico = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			colors: coresHighchart(),
			credits: {
				enabled: false
			},
			title: { text: periodo },
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
				valueSuffix: '%'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					showInLegend: true,
					dataLabels: {
						enabled: true,
						distance: -50,
						format: '{point.percentage:.1f}%',
						style: {
							fontSize: '1.3em',
							textOutline: 'none',
							opacity: 0.7
						},
					},

				},
			},
			series: [{
				name: "Porcentagem",
				colorByPoint: true,
				data: dadosGrafico
			}]
		};

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response?.data?.dados || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return(
			<DommusToggle title={titulo} toggle={true}>
              <div style={{height: height, overflow: "auto"}}>
                  {carregando ?
                      <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                      dadosGrafico?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} />:
                      <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                  }
              </div>
			</DommusToggle>
		)
	},
	BoxData: ({ titulo, data, invertido, cor, chave, height }) => {
		return <><DommusLoadingArea fundo={"#fff"} zindex={""} area={chave} />{invertido ? <div className="item d-flex flex-column justify-content-between disponiveis-estoque ml-auto new-dashboard"
			style={{ border: "1px solid " + cor, background: "#FFF", height: height ?? "auto" }}>
			<div>
				<h6 style={{ color: cor }}>{titulo}</h6>
			</div>
			<div className="controle-estoque-total">
				<p style={{ color: cor }}>{data.total}</p>
			</div>
		</div> : <div className="new-dashboard item d-flex flex-column justify-content-between disponiveis-estoque ml-auto"
			style={{ background: cor, height: height ?? "auto" }}>
			<div>
				<h6>{titulo}</h6>
			</div>
			<div className="controle-estoque-total">
				<p>{data.total}</p>
			</div>
		</div>}<br /></>
	},
	BarChart: ({ titulo, data,nome, chave }) => {
		const [dadosY, setDadosY] = useState([]);
		const [dadosX, setDadosX] = useState([]);
		useEffect(() => {
			setDadosX(data.map(item => {
				return [item.nome, item.contador];
			}));
		}, [data])

		const opcoesGrafico = {
			chart: {
				type: 'bar'
			},
			colors: coresHighchart(),
			title: {
				text: ""
			},
			xAxis: {
				type: 'category',
				labels: {
					style: {
						fontSize: '13px',
					}
				}
			},
			yAxis: {
				min: 0,
				title: {
					text: nome
				}
			},
			legend: {
				enabled: false
			},
			tooltip: {
				pointFormat: ' <b>{point.y}</b>'
			},
			credits: false,
			series: [{
				name: nome,
				data: dadosX
			}],
			plotOptions: {
				bar: {
					dataLabels: {
						enabled: true
					},
				}
			},
		};
		 return <>
		  <DommusToggle title={titulo} toggle={true}>
			{dadosX.length > 0 ? <>
				<DommusLoadingArea fundo={"#fff"} zindex={""} area={chave} />
				<HighchartsReact highcharts={Highcharts} options={opcoesGrafico} />	<br /></> :
				<Alert variant='warning'>Nenhum dado foi encontrado para o filtro selecionado.</Alert>
			}
		</DommusToggle></>
	},
	BarStackChart: ({ titulo, chave, filtrosGlobais, height="auto" }) => {
		const [dadosY, setDadosY] = useState([]);
		const [dadosX, setDadosX] = useState([]);
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')


		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosY(response?.data?.dados || []);
					setDadosX(response?.data?.categorias || []);
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		const opcoesGrafico = {
			chart: {
				type: 'bar'
			},
			colors: coresHighchart(),
			title: { text: periodo },
			xAxis: {
				type: 'category',
				labels: {
					style: {
						fontSize: '13px',
					}
				},
				categories: dadosX
			},
			yAxis: {
				min: 0,
				stackLabels: {
					enabled: true
				}
			},
			legend: {
				enabled: false
			},
			tooltip: {
				tooltip: {
					headerFormat: '<b>{point.x}</b><br/>',
					pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
				},
			},
			credits: false,
			plotOptions: {
				bar: {
					stacking: 'normal',
					dataLabels: {
						enabled: true
					},					
				}
			},
			series: dadosY,
		};
		return (
			<DommusToggle title={titulo} toggle={true}>
                <div style={{height: height, overflow: "auto"}}>
                  {carregando ?
                      <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                      dadosY?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} /> :
                      <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                  }		
                </div>
			</DommusToggle>
		)
	},
	GraficoColunasComLinhas: ({ chave, titulo, filtrosGlobais, height="auto" }) => {
		const [eixoX, setEixoX] = useState([])
		const [eixoY, setEixoY] = useState([])
		const [dadosGrafico, setDadosGrafico] = useState([])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')
		const opcoesGrafico = {
			chart: { zoomType: 'xy' },
			colors: coresHighchart(),
			title: { text: periodo },
			xAxis: eixoX,
			yAxis: eixoY,
			tooltip: { shared: true },
			legend: { enabled: true },
			series: dadosGrafico,
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true
					},
				}
			},
		}

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setEixoX(response.data?.eixoX || [])
					setEixoY(response.data?.eixoY || [])
					setDadosGrafico(response.data?.dados || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return (
			<DommusToggle title={titulo} toggle={true}>
              <div style={{height: height, overflow: "auto"}}>
                {carregando ?
                    <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                    eixoX?.categories?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} /> :
                    <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                }
              </div>
			</DommusToggle>
		)
	},
	GraficoColunas: ({ chave, titulo, filtrosGlobais, height="auto" }) => {
		const [eixoX, setEixoX] = useState([])
		const [eixoY, setEixoY] = useState([])
		const [dadosGrafico, setDadosGrafico] = useState([])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')
		const opcoesGrafico = {
			chart: { type: 'column' },
			title: { text: periodo },
			colors: coresHighchart(),
            
			xAxis: eixoX,
			yAxis: eixoY,
			tooltip: { 
                shared: true,
                formatter: function () {
                  return `<b>Total: ${this.y}</b><br><b>${this?.points[0]?.point?.extraValue ? `${this?.points[0]?.point?.extraValue}`: ""}</b>`
                }           
            },
			legend: { enabled: true },
			series: dadosGrafico,
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true
					},
				}
			},
		}

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setEixoX(response.data?.eixoX || [])
					setEixoY(response.data?.eixoY || [])
					setDadosGrafico(response.data?.dados || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return (
			<DommusToggle title={titulo} toggle={true}>
              <div style={{height: height, overflow: "auto"}}>
                {carregando ?
                    <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                    eixoX?.categories?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} /> :
                    <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                }
              </div>
			</DommusToggle>
		)
	},
	Cartao: ({ chave, titulo, filtrosGlobais, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState([])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response.data || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return (
			<DommusToggle title={titulo} toggle={true}>
				{carregando ?
					<DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
					dadosGrafico.length && (<div style={{height: height, overflow: "auto"}}>
						<Row>
							<Col>
								<div className="text-center mb-3" style={{ fontSize: '18px' }}>{periodo}</div>
							</Col>
						</Row>
						<Row>
							{dadosGrafico.map((cartao, index) => (
								<Col key={index}>
									<Card style={{ width: '15rem' }} className={`text-center mb-3 ${cartao.classe || ''}`}>
										<Card.Title className="pt-3 mb-0">{cartao.nome}</Card.Title>
										<Card.Body style={{fontSize: '30pt'}}>{cartao.total}</Card.Body>
									</Card>
								</Col>
							))}
						</Row>
					</div>) || <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
				}
			</DommusToggle>
		)
	},
	Tabela: ({ chave, titulo, filtrosGlobais, dadosPadroes=null, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState(dadosPadroes || [])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')

		useEffect(() => {
			if(!dadosPadroes) {
				let filtros = null
				if(!Object.keys(filtrosGlobais).length) {
					filtros = {
						data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
						data_final: moment().format('YYYY-MM-DD')
					}
					setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
				} else {
					setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
				}
				buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
			}
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response.data || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		const montarCabecalhos = useMemo(() => {
				return dadosGrafico.cabecalhos?.map((cabecalho) => <th key={`chave-${cabecalho}`}>{cabecalho.toUpperCase()}</th>)
		}, [dadosGrafico])

		const montarLinhas = useMemo(() => {
			return dadosGrafico.dados?.map((linha, index) => (
				<tr key={index}>
					{Object.values(linha).map((valor, index2) => <td key={index2}>{valor}</td>)}
				</tr>
			))
		}, [dadosGrafico])

		return (
			<DommusToggle title={titulo} toggle={true}>
				{carregando ?
					<DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
					dadosGrafico.dados?.length && (<div style={{height: height, overflow: "auto"}}>
						<Row>
							<Col>
								<div className="text-center mb-3" style={{ fontSize: '18px' }}>{periodo}</div>
							</Col>
						</Row>
						<DommusTabelaPaginada linhasCabecalho={montarCabecalhos} optionsTabela={{ striped: true }}linhasCorpo={montarLinhas}/>
					</div>) || <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
				}
			</DommusToggle>
		)
	},
	MultiplasTabelas: ({ chave, titulo, filtrosGlobais, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState([])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response.data || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return (
			<DommusToggle title={titulo} toggle={true}>
				{carregando ?
					<DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
					dadosGrafico?.length && (<div style={{height: height, overflow: "auto"}}>
						<Row>
							<Col>
								<div className="text-center mb-3" style={{ fontSize: '18px' }}>{periodo}</div>
							</Col>
						</Row>
						<div className="d-flex quadro justify-content-between">
							{dadosGrafico.map(tabela => (
								<div className="coluna" style={{ backgroundColor: tabela.cor }}>
								 <div className="cabecalho-coluna text-center text-light">{tabela.titulo}</div>
								 {tabela.dados.map(linha => (
									<div className="item">
										{linha.nome}<span>{linha.total}</span>
									</div>
								 ))}
								</div>
							))}
						</div>
					</div>) ||
					<DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
				}
			</DommusToggle>
		)
	},
	Linhas: ({ chave, titulo, filtrosGlobais, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState([])
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')
		const opcoesGrafico = {
			chart: { type: 'line' },
			title: { text: periodo},
			yAxis: { title: { text: 'Total' } },
			xAxis: { categories: dadosGrafico.eixoX },
			colors: coresHighchart(),
			legend: {
				layout: 'vertical',
				align: 'right',
				verticalAlign: 'middle'
			},
			plotOptions: {
				series: { label: { connectorAllowed: false }},
			},
			series: dadosGrafico.dados,
			responsive: {
				rules: [{
					condition: { maxWidth: 500 },
					chartOptions: {
						legend: {
							layout: 'horizontal',
							align: 'center',
							verticalAlign: 'bottom'
						}
					}
				}]
			}

		}
		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response.data || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return (
			<DommusToggle title={titulo} toggle={true}>
                <div style={{height: height, overflow: "auto"}}>
                  {carregando ?
                      <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                      dadosGrafico.eixoX?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} /> :
                      <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                  }
                </div>
			</DommusToggle>
		)
	},
  	DonutChart: ({ titulo, chave, filtrosGlobais, height="auto" }) => {
		const [dadosGrafico, setDadosGrafico] = useState([]);
		const [carregando, setCarregando] = useState(false)
		const [periodo, setPeriodo] = useState('')

		let opcoesGrafico = {
			chart: {
				plotBackgroundColor: null,
				plotBorderWidth: null,
				plotShadow: false,
				type: 'pie'
			},
			colors: coresHighchart(),
			credits: {
				enabled: false
			},
			title: { text: periodo },
			tooltip: {
				pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
				valueSuffix: '%'
			},
			accessibility: {
				point: {
					valueSuffix: '%'
				}
			},
			plotOptions: {
				pie: {
					allowPointSelect: true,
					cursor: 'pointer',
					showInLegend: true,
					dataLabels: {
						enabled: true,
						distance: -25,
						format: '{point.percentage:.1f}%',
						style: {
							fontSize: '1.3em',
							textOutline: 'none',
							opacity: 0.7
						},
					},

				},
			},
			series: [{
				name: "Porcentagem",
				colorByPoint: true,
				data: dadosGrafico,
                size: '80%',
                innerSize: '50%',
			}]
		};

		useEffect(() => {
			let filtros = null
			if(!Object.keys(filtrosGlobais).length) {
				filtros = {
					data_inicial: moment().subtract(3, 'months').date(1).format('YYYY-MM-DD'),
					data_final: moment().format('YYYY-MM-DD')
				}
				setPeriodo(`Período: ${moment(filtros.data_inicial).format('DD/MM/YYYY')} - ${moment(filtros.data_final).format('DD/MM/YYYY')}`)
			} else {
				setPeriodo(`Período: ${moment(filtrosGlobais.data_inicial).format('DD/MM/YYYY')} - ${moment(filtrosGlobais.data_final).format('DD/MM/YYYY')}`)
			}
			buscarDadosGrafico(Object.keys(filtrosGlobais).length && filtrosGlobais || filtros)
		}, [filtrosGlobais])

		function buscarDadosGrafico(filtros) {
			setCarregando(true)
			GraficoService.buscarDadosGraficoPorChave(chave, filtros)
				.then(response => {
					setDadosGrafico(response?.data?.dados || [])
					setCarregando(false)
				}).catch(error => {
					console.log(error)
					console.log(error?.response)
					errorToast.fire({ text: 'Houve um erro ao tentar buscar os dados do gráfico.' })
					setCarregando(false)
				})
		}

		return(
			<DommusToggle title={titulo} toggle={true}>
              <div style={{height: height, overflow: "auto"}}>
                  {carregando ?
                      <DommusLoading loadWrapperPosition={"relative"} fundo="#eee" style={{ minHeight: '400px' }}/> :
                      dadosGrafico?.length ? <HighchartsReact highcharts={Highcharts} options={opcoesGrafico} />:
                      <DommusAlerta mensagem="Nenhum dado foi encontrado para o filtro selecionado." classe="text-center"/>
                  }
              </div>
			</DommusToggle>
		)
	},
};

export default DommusCharts;
