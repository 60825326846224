import { trackPromise } from "react-promise-tracker"
import Swal from "sweetalert2"

export function CachearContexto (chave, setStateChave, promise, loading=false, testeCacheContexto=null) {
    if((testeCacheContexto == null && chave?.length) || testeCacheContexto) {
        return 0
    }
    if(loading) {
        trackPromise(promise
            .then(response => setStateChave(response.data))
            .catch((error) => {
                Swal.fire(
                    'Ooops ...',
                    'Houve um erro ao tentar carregar dependencias.',
                    'error'
                );
                return false
            })
        )
    } else {
        promise
            .then(response => setStateChave(response.data))
            .catch((error) => {
                Swal.fire(
                    'Ooops ...',
                    'Houve um erro ao tentar carregar dependencias.',
                    'error'
                );
                return false
            })
    }
    return 1
}
