import React, { Fragment, useContext, useEffect, useMemo, useState } from "react";
import "./index.css";
import { Alert, Container, Form } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import DommusBarraTitulo from "../../components/DommusBarraTitulo";
import ItemBarraTitulo from "../../components/DommusBarraTitulo/ItemBarraTitulo";
import { CachearContexto } from "../../components/CacherContexto";
import { ConsumoConectaCardsTopo } from "./ConsumoConectaCardsTopo";
import { ConsumoConectaGraficos } from "./ConsumoConectaGraficos";
import { ConsumoConectaTabelas } from "./ConsumoConectaTabelas";
import { PacoteConsumoContext } from "../../context/PacoteConsumoContext";
import PacoteConsumoService from "../../services/PacoteConsumoService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import formatDommus from "../../helpers/format";

export function ConsumoConecta(){
    const {pacotesConsumo, setPacotesConsumo, mesReferencia, setMesReferencia} = useContext(PacoteConsumoContext);

    useEffect(()=>{
        CachearContexto(pacotesConsumo, setPacotesConsumo, PacoteConsumoService?.buscarPorModulo(6, mesReferencia));
    },[])

    const optionsMeses = useMemo(()=>{
      const now = moment();
      const ano = now.format('Y');
      const mes = now.format('MM');
      let options = [];

      for(let i=Number(mes); i>0; i--){
        options.push(
          <option value={`${ano}-${i.toString().padStart(2, '0')}`}>{formatDommus.resolveNomeMesPorNumero(i)}/{ano}</option>
        )
      }
      

      return options;
    },[])

    return (
    <>
      <ReactTooltip />
      <Container fluid>
        <DommusBarraTitulo>
          <ItemBarraTitulo>
            <div className="title-section">
              <span>
                  Consumo - Dommus Conecta
              </span>
              <Form.Group>
                <Form.Control
                  as={"select"}
                  name={"select1"}
                  onChange={(event)=>{
                    setMesReferencia(event?.target?.value)
                  }}                   
                >
                  {optionsMeses.map((item, index)=>{
                    return (
                      <Fragment key={index}>
                        {item}
                      </Fragment>
                    )
                  })}
                </Form.Control>
              </Form.Group>
            </div>
          </ItemBarraTitulo>
        </DommusBarraTitulo>
        {pacotesConsumo?.length ?
            <div className="consumo-conecta-section">
              <ConsumoConectaCardsTopo/>
              <ConsumoConectaGraficos/>
              <ConsumoConectaTabelas/>
            </div>
        : <Alert variant="warning"><FontAwesomeIcon icon={faExclamationTriangle}/> Não foi encontrado nenhum pacote cadastrado!</Alert>
        }
      </Container>
    </>
    )
}
