import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";

export function DommusAlerta({ mensagem, classe, variant='warning', icone=faExclamationTriangle }) {
    return (
        <Alert variant={variant} className={classe}>
            <FontAwesomeIcon icon={icone}/> {mensagem}
        </Alert>
    )
}
