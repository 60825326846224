import React from 'react';
import './App.css';
import './assets/poppins/poppins.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './routes';
import {BrowserRouter} from "react-router-dom";
import DommusPromiseLoading from "./components/DommusLoading/DommusPromiseLoading";
import { AppContextProvider } from './context/AppContext';
import { PacoteConsumoProvider } from './context/PacoteConsumoContext';

function App() {

	return (
            <BrowserRouter>
                <DommusPromiseLoading/>
                <AppContextProvider>
                    <PacoteConsumoProvider>
                        <Routes/>
                    </PacoteConsumoProvider>
                </AppContextProvider>
            </BrowserRouter>
	);
}

export default App;
