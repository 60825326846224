import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import ReactSelect from 'react-select';
import DesenvolvedoresService from '../../../services/desenvolvedorService';
import guService from '../../../services/guService';
import './index.css';
import MultiSelect from "react-multi-select-component";


export default function ConfigUsuarioAPI({ usuarioAcesso = null, modulo, tipo, fecharModal }) {
    const [optionsMetodos, setOptionsMetodos] = useState([]);
    const [optionsRecursos, setOptionsRecurso] = useState([]);
    const [optionsUsuarios, setOptionUsuarios] = useState([]);
    const [acessos, setAcessos] = useState(usuarioAcesso ? usuarioAcesso?.acessos : {});
    const [usuario, setUsuario] = useState(usuarioAcesso ?? {});
    const [acessoHtml, setAcessoHtmls] = useState([]);

    const [formAdicionar, setFormAdicionar] = useState({
        recurso: '',
        usuario: usuarioAcesso,
    });

    const handleChangeSelectAdicionar = (campo, event) => {
        setFormAdicionar({
            ...formAdicionar,
            [campo]: event,
        });
    };

    const handleSubmit = (event) => {

        event.preventDefault();
        formAdicionar.recurso.map(rec => {
            setAcessos(prevAcessos => ({
                ...prevAcessos,
                [rec.value]: []
            }));
        })
     
        setUsuario(formAdicionar?.usuario)
        setFormAdicionar({
            ...formAdicionar,
            recurso: null,
        });
    };

    function buscarMetodos() {
        trackPromise(DesenvolvedoresService.buscarMetodos({ id_modulo: modulo }))
            .then((response) => {
                let optionsMetodos = response.data?.map(metodo => {
                    return { label: metodo.metodo.toUpperCase(), value: metodo.id }
                })
                setOptionsMetodos(optionsMetodos)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function buscarUsuarios() {
        trackPromise(guService.usuario.buscarListaUsuarios())
            .then((response) => {
                let optionsUsuario = response.data.map(usuario => {
                    return { value: usuario?.id, label: usuario?.usuario?.nome }
                })
                setOptionUsuarios(optionsUsuario)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function buscarRecursos() {
        trackPromise(DesenvolvedoresService.buscarRecursos({ id_modulo: modulo }))
            .then((response) => {
                let optionsRecurso = response.data?.map(recurso => {
                    return { label: recurso.recurso.toUpperCase(), value: recurso.id }
                })
                setOptionsRecurso(optionsRecurso)
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um ao buscar a lista de desenvolvedores.', 'error')
            })
    }

    function montaAcessos(acessos) {
        let retorno = Object.entries(acessos).map(([chave, acesso]) => {

            let valueRecurso = null;
            optionsRecursos.forEach(option => {
                if (chave.includes(option.value)) {
                    valueRecurso = option;
                }
            })

            let valueMetodo = optionsMetodos.filter(option => {
                return acesso.includes(String(option.value));
            })
            return { recurso: valueRecurso, metodos: valueMetodo }

        })
        setAcessoHtmls(retorno)
    }

    function removerRecurso(recurso) {
        const novoAcessos = { ...acessos };
        delete novoAcessos[recurso.value];
        setAcessos(novoAcessos);
    }

    function removeOptionUsadas(option) {
        let optionsValidas = option.filter(option => {
            return !Object.keys(acessos)?.includes(String(option.value));
        })
        return optionsValidas ?? [];
    }

    function handleChangeMetodos(metodosSelect, recurso) {
        let metodos = metodosSelect.map(metodo => {
            return String(metodo.value);
        })
        setAcessos(prevAcessos => ({
            ...prevAcessos,
            [recurso?.value]: metodos
        }));

    }

    function atualizarAcessos(acessos) {
        let objetoAcesso = {
            usuario: usuarioAcesso.id,
            modulo: modulo,
            acessos: acessos
        }

        trackPromise(DesenvolvedoresService.salvarAcessos(objetoAcesso))
            .then((response) => {
                fecharModal()
            }).catch(error => {
                Swal.fire('Ooops...', 'Houve um erro ao atualizar os acessos..', 'error')
            })
    }

    function salvarAcessos(acessos) {
      if (acessos?.length) {
        let objetoAcesso = {
          usuario: formAdicionar?.usuario?.value,
          modulo: modulo,
          acessos: acessos.map((acesso) => {
            return {
              recurso: acesso?.recurso?.value,
              metodos: acesso?.metodos?.map((metodo) => metodo.value),
            };
          }),
        };
        trackPromise(DesenvolvedoresService.salvarAcessos(objetoAcesso))
          .then((response) => {
            fecharModal();
          })
          .catch((error) => {
            Swal.fire(
              "Ooops...",
              "Houve um erro ao salvar os acessos.",
              "error"
            );
          });
      }
    }

    function handleFecharModal() {
        fecharModal()
    }

    useEffect(() => {
        buscarRecursos()
        buscarMetodos()
    }, [])

    useEffect(() => {
        montaAcessos(acessos)
    }, [acessos, optionsMetodos, optionsRecursos])

    useEffect(() => {
        buscarUsuarios()
    }, [tipo == 'Adicionar'])

    const configs = {
		selectSomeItems: "Selecione",
		allItemsAreSelected: "Todos itens selecionados",
		selectAll: "Selecionar todos",
		search: "Pesquisar",
	};

    return (
      <>
      <Container fluid style={{minHeight: '40rem'}}>
      <Form onSubmit={handleSubmit}>
        <Row className="g-3">
          {tipo === "Criar" && !usuarioAcesso && (
            <Col md={6} lg={4}>
              <Form.Label>Usuário</Form.Label>
              <ReactSelect
                options={optionsUsuarios || []}
                name="usuario"
                onChange={(e) => handleChangeSelectAdicionar("usuario", e)}
                value={formAdicionar.usuario}
              />
            </Col>
          )}
          <Col md={6} lg={4}>
            <Form.Label>Recurso</Form.Label>
            <MultiSelect
              options={removeOptionUsadas(optionsRecursos)}
              name="recurso"
              onChange={(e) => handleChangeSelectAdicionar("recurso", e)}
              value={formAdicionar.recurso}
              overrideStrings={configs}
            />
          </Col>
          <Col md={12} lg={2} className="d-flex align-items-end">
            <Button variant="primary" type="submit" className="w-100">
              Adicionar
            </Button>
          </Col>
        </Row>
      </Form>

      <Row style={{ maxHeight: '37rem', minHeight: "25rem", overflowY: 'auto' }}>
        {acessoHtml &&
          acessoHtml.map((acesso, index) => (
            <React.Fragment key={index}>
              <Col md={6} lg={4}>
                <Form.Label>{acesso?.recurso?.label}</Form.Label>
                <MultiSelect
                options={optionsMetodos}
                name="recurso"
                onChange={(e) => handleChangeMetodos(e, acesso?.recurso)}
                value={acesso.metodos}
                overrideStrings={configs}
                />
              </Col>
              <Col md={6} lg={2} className="d-flex align-items-center">
                <Button
                  variant="danger"
                  onClick={() => removerRecurso(acesso.recurso)}
                  className="w-100"
                >
                  Remover
                </Button>
              </Col>
            </React.Fragment>
          ))}
      </Row>

      <Row className="mt-4">
        <Col className="d-flex justify-content-end gap-2">
          {tipo === "Editar" ? (
            <Button
              variant="success"
              onClick={() => atualizarAcessos(acessoHtml)}
            >
              Atualizar
            </Button>
          ) : (
            <Button
              variant="success"
              onClick={() => salvarAcessos(acessoHtml)}
            >
              Salvar
            </Button>
          )}
          <Button variant="danger" onClick={handleFecharModal}>
            Fechar
          </Button>
        </Col>
      </Row>
    </Container>
      </>
    );
}
