import React, { useContext, useMemo } from "react";
import "./index.css"
import DommusCharts from "../../../components/DommusCharts";
import { PacoteConsumoContext } from "../../../context/PacoteConsumoContext";
import moment from "moment";

export function ConsumoConectaGraficos(){
  const {mesReferencia, dataFinalPeriodo, canalAtivo} = useContext(PacoteConsumoContext);
  const graficoConsumoMensal = useMemo(()=>{
    let canal = ['whatsapp-conversas', 'whatsapp-templates'].includes(canalAtivo) ? 'whatsapp' : canalAtivo;
    if(mesReferencia){
      let filtros = {
        data_inicial: moment(`${mesReferencia}-01`).startOf('year').format('YYYY-MM-DD'),
        data_final: dataFinalPeriodo(),
        mes_referencia: mesReferencia,
        meio_comunicacao: canal ?? null,
      }
      return (
        <DommusCharts.GraficoColunas chave="consumo-conecta-mensal" titulo="Comparativo mensal x últimos 12 meses" filtrosGlobais={filtros}/>
      )
    }else{
      return <></>
    }
  },[mesReferencia, canalAtivo])

  const graficoConsumoPorCanalColuna = useMemo(()=>{
    let canal = ['whatsapp-conversas', 'whatsapp-templates'].includes(canalAtivo) ? 'whatsapp' : canalAtivo;
    if(mesReferencia){
      let filtros = {
        data_inicial: moment(`${mesReferencia}-01`).startOf('month').format('YYYY-MM-DD'),
        data_final: dataFinalPeriodo(),
        mes_referencia: mesReferencia,
        meio_comunicacao: canal,
      }
      return (
        <DommusCharts.Linhas chave="consumo-conecta-por-canal" titulo="Consumo do mês por canal" filtrosGlobais={filtros}/>
      )
    }else{
      return <></>
    }
  },[mesReferencia, canalAtivo])

  const graficoConsumoPorCanalDonut = useMemo(()=>{
    let canal = ['whatsapp-conversas', 'whatsapp-templates'].includes(canalAtivo) ? 'whatsapp' : canalAtivo;
    if(mesReferencia){
      let filtros = {
        data_inicial: moment(`${mesReferencia}-01`).startOf('month').format('YYYY-MM-DD'),
        data_final: dataFinalPeriodo(),
        mes_referencia: mesReferencia,
        meio_comunicacao: canal ?? null,
      }
      return (
        <DommusCharts.DonutChart chave="consumo-conecta-por-canal-percentual" titulo="% do canal sobre o total do mês" filtrosGlobais={filtros}/>
      )
    }else{
      return <></>
    }
  },[mesReferencia, canalAtivo])

  return (
    <div className="grafico-section">
      <div className="grafico-consumo grafico-consumo-mensal">
        {graficoConsumoMensal}
      </div>
      <div className="grafico-consumo grafico-consumo-canal">
        {graficoConsumoPorCanalColuna}
      </div>
      <div className="grafico-consumo grafico-consumo-verificar">
        {graficoConsumoPorCanalDonut}
      </div>
    </div>
  )
} 
