import React, { Fragment, useCallback, useContext, useMemo } from "react";
import "./index.css";
import moment from "moment";
import { PacoteConsumoContext } from "../../../context/PacoteConsumoContext";
import { CardPacote } from "./CardPacote";
import formatDommus from "../../../helpers/format";

const meios = ['voip', 'whatsapp', 'sms', 'email']

export function ConsumoConectaCardsTopo(){
    const {pacotesConsumo, meiosComunicacaoPacotes, canalAtivo, setCanalAtivo, valoresPacotesConsumo} = useContext(PacoteConsumoContext);
    const month = moment().format('MMMM/YYYY');

    const cardSemPacote = useCallback((titulo, subtitulo)=>{
        return(
            <div 
                className={`card-section`}
            >
                <div className="title-section">
                  <span className="title-card">{titulo}</span>
                  <span className="subtitle-card">{subtitulo}</span>
                </div>
                <span className="info-card">Pacote não encontrado</span>
            </div>
        )
    },[])

    const cardsPorMeio = useMemo(()=>{
        const cards = [];
        const cardsSemPacote = [];
        meios.forEach((item)=>{
            switch(item){
                case 'voip':
                    if(meiosComunicacaoPacotes.includes(item)){
                        cards.push(
                            <CardPacote 
                                titulo={"VOIP"}
                                meio={'voip'}
                                unidadeConsumo={"Minutagem"}
                            />
                        )
                    }else{
                        cardsSemPacote.push(cardSemPacote('VOIP'));
                    }
                    break;
                case 'whatsapp':
                        if(meiosComunicacaoPacotes.includes(item)){
                            cards.push(
                              <>
                                <CardPacote 
                                    titulo={"Whatsapp"}
                                    subtitulo={"Conversas"}
                                    meio={'whatsapp-conversas'}
                                    unidadeConsumo={"Conversas"}
                                />
                                <CardPacote 
                                    titulo={"Whatsapp"}
                                    subtitulo={"Templates"}
                                    meio={'whatsapp-templates'}
                                    unidadeConsumo={"Templates"}
                                />
                              </>
                            )
                        }else{
                            cardsSemPacote.push(
                                <>
                                    {
                                        cardSemPacote('Whatsapp', 'Conversas')
                                    }
                                    {
                                        cardSemPacote('Whatsapp', 'Templates')
                                    }
                                </>
                            )
                        }
                    break;
                case 'sms':
                    if(meiosComunicacaoPacotes.includes(item)){
                        cards.push(
                            <CardPacote 
                                    titulo={"SMS"}
                                    meio={'sms'}
                                    unidadeConsumo={"Mensagens"}
                            />
                        )
                    }else{
                        cardsSemPacote.push(cardSemPacote('SMS'));
                    }
                    break;
                case 'email':
                    if(meiosComunicacaoPacotes.includes(item)){
                        cards.push(
                            <CardPacote 
                                titulo={"E-mail"}
                                meio={'email'}
                                unidadeConsumo={"Mensagens"}
                            />
                        );
                    }else{
                        cardsSemPacote.push(cardSemPacote('E-mail'));
                    }
                    break;
                default:
                    break;
            }
        })
        return [...cards, ...cardsSemPacote];
    },[pacotesConsumo, meiosComunicacaoPacotes, canalAtivo])

    const valorTotal = useMemo(()=>{
        let valor = 0;

        Object.keys(valoresPacotesConsumo).forEach((key)=>{
            valor += valoresPacotesConsumo[key]?.valor_total ?? 0;
        })
        return valor ?? 0;
    },[valoresPacotesConsumo])

    return (
        <div className="cards-section">
          <div 
            className={`card-section ${canalAtivo === null ? 'ativo' : ''}`}
            onClick={()=> setCanalAtivo(null)}
          >
            <div className="title-section">
              <span className="title-card" style={{textTransform: 'capitalize'}}>{month}</span>
            </div>
            {valorTotal ? 
            <span className="valor-card">{formatDommus.formatarReal(valorTotal ?? 0)}</span>
            : <span className="info-card">Valor não encontrado</span>
            }
            <div className="consumo-section">
              <span className="title-consumo">Consumo Total</span>
            </div>
          </div>
         {cardsPorMeio?.map((item, index)=>{
                return(
                    <Fragment key={index}>
                        {item}
                    </Fragment>
                )
            })}
        </div>
    )
}
